import React from "react";
import Layout from "../../layout/appbar/Layout";
import {
  Box,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  gridBox,
  gridStyle,
  headingParaBox,
  headingParaTypography,
  headingTypography,
} from "../recordReabilitation/RecordRehabilitationStyle";
import screenSnap from "../../assets/images/images/screenSnap1.png";
import screenSnap2 from "../../assets/images/images/Screenshot7.png";
import screenSnap3 from "../../assets/images/images/Screenshot8.png";
import screenSnap4 from "../../assets/images/images/Screenshot9.png";

const Remove = ({ removeRef }) => {
  return (
    <div>
      <Toolbar ref={removeRef} />
      <Container>
        <Box>
          <Typography variant="h4" sx={headingTypography}>
            Delete account
          </Typography>
          <Box sx={headingParaBox}>
            <Typography variant="p" sx={headingParaTypography}>
              Are you sure you want to delete your account?
              <br />
              Your all data related to exercise will be remove from the system.
            </Typography>
          </Box>
          <Container>
            <Box>
              <Container>
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    mt: "1rem",
                    textAlign: "center",
                  }}
                >
                  (1) Open App &gt; Go to settings &gt; Click remove account
                  &gt; Press yes to remove account
                </Typography>
              </Container>
            </Box>
          </Container>
          <Container sx={{ paddingLeft: { xs: "0px", sm: "24px" } }}>
            <Grid container sx={gridStyle} spacing={3}>
              <Grid item xs={3} md={2}>
                <Box
                  component="img"
                  src={screenSnap}
                  sx={{
                    maxWidth: "100%",
                    mt: "2rem",
                    height: { xs: "6rem", sm: "17rem", md: "18rem" },
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <Box
                  component="img"
                  src={screenSnap2}
                  sx={{
                    maxWidth: "100%",
                    mt: "2rem",
                    height: { xs: "6rem", sm: "17rem", md: "18rem" },
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <Box
                  component="img"
                  src={screenSnap3}
                  sx={{
                    maxWidth: "100%",
                    mt: "2rem",
                    height: { xs: "6rem", sm: "17rem", md: "18rem" },
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <Box
                  component="img"
                  src={screenSnap4}
                  sx={{
                    maxWidth: "100%",
                    mt: "2rem",
                    height: { xs: "6rem", sm: "17rem", md: "18rem" },
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
      <Toolbar />
    </div>
  );
};

export default Remove;
