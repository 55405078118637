import React from "react";
import Appbar from "./appbar/Appbar";

const index = ({ children }) => {
  return (
    <div>
      <Appbar />
      {children}
    </div>
  );
};

export default index;
