export const headingTypography = {
  "&.MuiTypography-root": {
    textAlign: "center",
    fontWeight: "600",
    mb: "1rem",
    fontSize: { xs: "30px", sm: "43px" },
  },
};

export const headingParaBox = {
  "&.MuiBox-root": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    mt: "1rem",
  },
};

export const headingParaTypography = {
  "&.MuiTypography-root": {
    fontSize: "14px",
    textAlign: "center",
    lineHeight: "1.5",
    width: { sm: "90%", md: "51%" },
  },
};

export const gridStyle = {
  "&.MuiGrid-root": {
    width: "97%",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItem: "space-between",
  },
};

export const gridBox = {
  "&.MuiBox-root": {
    maxWidth: "100%",
    mt: "2rem",
  },
};
