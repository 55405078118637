import { AppBar, Box, Container, Grid, IconButton } from "@mui/material";
import { iconButtonDrawer } from "./AppbarStyle";
import React, { useState } from "react";
import logo from "../../assets/images/images/Rehabilitendon_logo_white.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import "./Hamburger.css";
import { useNavigate } from "react-router-dom";

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setMobileOpen((prevState) => !prevState);
  };
  const handleNavigate = (e) => {
    e.preventDefault();
    navigate("/");
  };
  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          background: "#1e2732",
          boxShadow: "none",
          transition: "padding 5s ease-in-out",
          paddingTop: { xs: "unset", md: "35px" },
          paddingBottom: { xs: "unset", md: "35px" },
        }}
      >
        <Container>
          <Grid
            container
            sx={{ display: "flex", alignItems: "center!important" }}
          >
            <Grid item xs={6}>
              <Box
                src={logo}
                component="img"
                onClick={(e) => handleNavigate(e)}
                sx={{ width: { xs: "11rem", md: "12rem" }, cursor: "pointer" }}
              />
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                sx={{
                  background: "#3aafd6",
                  color: "white",
                  "&.hover": { backgroundColor: "#333" },
                  padding: "3px 8px!important",
                  display: { xs: "none", md: "unset" },
                }}
              >
                <TwitterIcon sx={{ width: "18px", height: "18px" }} />
              </IconButton>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <div
                  id="nav-icon1"
                  className={`navbar ${isOpen ? "open" : ""}`}
                  onClick={handleToggle}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>

        {isOpen && (
          <div className="additional-content ">
            <Container>
              <IconButton sx={iconButtonDrawer}>
                <TwitterIcon sx={{ width: "18px", height: "18px" }} />
              </IconButton>
            </Container>
          </div>
        )}
      </AppBar>
    </div>
  );
};

export default Layout;
