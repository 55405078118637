export const background = {
  "&.MuiBox-root": {
    background: "#f2f6f9",
    height: "100vh",
    maxWidth: "100%",
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
export const pprBox = {
  "&.MuiBox-root": {
    lg: { width: "33%" },
    md: { width: "50%" },
    xs: { width: "75%" },
    sm: { width: "63%" },
  },
};
export const passwordStyle = {
  "&.MuiFormControl-root": {
    marginLeft: 0,
    width: "100%",
    marginTop: "20px",
  },
};
export const emailDesign = {
  "&.MuiTextField-root": {
    width: "100%",
    marginTop: "20px",
  },
};
export const loginkeyWord = {
  "&.MuiTypography-root": {
    textAlign: "center",
    paddingTop: "30px",
    fontWeight: "600",
  },
};
export const signupkeyWord = {
  "&.MuiTypography-root": {
    textAlign: "center",
    paddingTop: "14px",
    fontWeight: "600",
  },
};
export const account = {
  "&.MuiTypography-root": {
    textAlign: "center",
    paddingBottom: "25px",
    fontSize: "14px",
    color: "#93d3de",
  },
};

export const forgetpassword = {
  "&.MuiLink-root": {
    display: "flex",
    justifyContent: "end",
    marginTop: "15px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#93d3de",
    textDecoration: "none",
    textDecorationColor: "none",
    cursor: "pointer",
  },
};
export const signup = {
  "&.MuiLink-root": {
    fontSize: "14px",
    fontWeight: "600",
    color: "#93d3de",
    textDecoration: "none",
    textDecorationColor: "none",
    cursor: "pointer",
  },
};
export const loginLogo = {
  "&.MuiBox-root": {
    display: "flex",
    mr: "15px",
    width: "10%",
  },
};
export const paper = {
  "&.MuiPaper-root": {
    py: "37px",
  },
};
export const paperSignup = {
  "&.MuiPaper-root": {
    py: "10px",
  },
};
export const papermainbox = {
  "&.MuiBox-root": {
    display: "flex",
    justifyContent: "center",
  },
};
export const paperTypography = {
  "&.MuiTypography-root": {
    fontWeight: "bold",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
export const loginbutton = {
  "&.MuiButton-root": {
    marginTop: "20px",
    marginBottom: "34px",
    width: "100%",
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: "8px",
    background:
      "linear-gradient(135deg, rgba(191,201,207,1) 2%, rgba(39,106,117,1) 34%);",
  },
};

export const resetpasswordNote = {
  "&.MuiTypography-root": {
    lineHeight: "1.5",
    color: "#FF4B55",
    xs: { width: "76%" },
    sm: { width: "44%" },
    md: { width: "40%" },
    margin: "auto",
    textAlign: "center",
    fontSize: "10px",
    marginTop: "7px",
  },
};
