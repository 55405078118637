import {
  Box,
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  Modal,
  Toolbar,
  Typography,
  keyframes,
  styled,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import mobileImg from "../../assets/images/images/uploads/hero-devices-3-2-3.png";
import Layout from "../../layout/index";
import {
  backgroundColor,
  backgroundImg,
  gridFlex,
  gridImg,
  gridTypography,
  gridTypographyP,
} from "./HomeStyle";
import PropTypes from "prop-types";

import RecordRehabilitation from "../recordReabilitation/RecordRehabilitation";
import JoinMail from "../joinMail/JoinMail";
import Footer from "../Footer/Footer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { loginbutton } from "../Login/LoginStyle";
import Remove from "../remove/Remove";
import { useLocation } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid white!important",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};
const rollInLeft = keyframes`
  0% {
    -webkit-transform: translateY(0px) rotate(0);
            transform: translateX(-35px) rotate(0);
    opacity: 2;
  }
  100% {
    -webkit-transform: translateY(0px) rotate(0deg);
            transform: translateX(0px) rotate(0deg);
    opacity: 2;
  }
`;
const Hold = styled(Box)(({ roll }) => ({
  display: "flex",
  visibility: !roll && "hidden",
  animation: roll && `${rollInLeft} 1s ease-out both`,
}));
function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const Home = (props) => {
  const [myElementIsVisible, setMyElementIsVisible] = React.useState();
  const [roll, setRoll] = React.useState(false);
  const homeRef = React.useRef(null);
  const removeRef = useRef(null);
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    setRoll(true);
  }, []);
  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
  }, []);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(homeRef.current);
  }, [homeRef]);
  useEffect(() => {
    {
      location.search === "?remove" &&
        removeRef.current.scrollIntoView({
          behavior: "smooth",
          TransitionEvent: "3s",
        });
    }
  }, [removeRef]);
  const location = useLocation();
  console.log(location.search);
  return (
    <div>
      <Layout />
      <div id="back-to-top-anchor"></div>
      <Box sx={backgroundImg}>
        <Box sx={backgroundColor} ref={homeRef}>
          <Container>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} sx={gridFlex}>
                <Box>
                  <Typography variant="h3" sx={gridTypography}>
                    <Typography variant="span" sx={{ fontWeight: "600" }}>
                      REHABILI
                    </Typography>
                    TENDON
                  </Typography>
                  <Typography variant="p" sx={gridTypographyP}>
                    Our app is designed to supercharge your strength training by
                    firing up the connections between your brain and your
                    muscles. This will improve both your strength and your pain.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Hold roll={myElementIsVisible ? roll : ""}>
                  <Box sx={{ width: { xs: "unset", sm: "80%" } }}>
                    <Box component="img" src={mobileImg} sx={gridImg} />
                  </Box>
                </Hold>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <RecordRehabilitation />

      {location.search === "?remove" && <Remove removeRef={removeRef} />}
      <JoinMail />
      <Footer />
      <Container>
        <ScrollTop {...props}>
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{
              background: "#379bbc",
              color: "white",
              "&:hover": {
                background: "#3aafd6",
                color: "white",
              },
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </Container>
      {/* <Box>
        <Modal
          sx={{
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button sx={{ minWidth: "0px" }} onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Box>

            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              <b>Adeel Hamza</b>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
              }}
              variant="span"
            >
              hsarwar234@gmail.com
            </Typography>
            <Typography sx={{ my: 2, textAlign: "center" }}>
              Are you sure you want to delete your account?
              <br />
              Your all data related to exercise will be remove from the system.
            </Typography>
            <Button variant="contained" sx={loginbutton} type="submit">
              Remove Account
            </Button>
          </Box>
        </Modal>
      </Box> */}
    </div>
  );
};

export default Home;
