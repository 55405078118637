import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    navigate("/download-evidence");
  };
  const handlePrivacyClick = (e) => {
    e.preventDefault();
    navigate("/privacy");
  };
  const handleTermClick = (e) => {
    e.preventDefault();
    navigate("/term");
  };

  return (
    <div>
      <Box sx={{ background: "#444444", pt: "2rem", pb: "4rem" }}>
        <Container>
          <Typography variant="span" sx={{ color: "white" }}>
            Send us an email at
            <Link sx={{ color: "#3aafd6", textDecoration: "none" }}>
              {" "}
              info@rehabilitendon.com.au{" "}
            </Link>
          </Typography>

          <Typography
            variant="span"
            sx={{
              height: "1rem",
              width: "1rem",
              color: "white",
              borderRadius: "1rem",
            }}
          >
            .
          </Typography>
          <Typography variant="span">
            <Link
              onClick={(e) => handleClick(e)}
              sx={{
                color: "#3aafd6",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Evidence & Sources of Medical Informations
            </Link>
          </Typography>
          <Typography
            variant="span"
            sx={{
              height: "1rem",
              width: "1rem",
              color: "white",
              borderRadius: "1rem",
            }}
          >
            .
          </Typography>
          <Typography variant="span">
            <Link
              onClick={(e) => handleTermClick(e)}
              sx={{
                color: "#3aafd6",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Terms and Conditions
            </Link>
          </Typography>
          <Typography
            variant="span"
            sx={{
              height: "1rem",
              width: "1rem",
              color: "white",
              borderRadius: "1rem",
            }}
          >
            .
          </Typography>
          <Typography variant="span">
            <Link
              onClick={(e) => handlePrivacyClick(e)}
              sx={{
                color: "#3aafd6",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Privacy Policy
            </Link>
          </Typography>
          <Typography variant="p" sx={{ display: "block", color: "white" }}>
            © 2023 Rehabilitendon All Rights Reserved.
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default Footer;
