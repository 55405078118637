import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import React from "react";
import backImg from "../../assets/images/images/newsletter-image-bg.jpg";
import {
  headingParaBox,
  headingParaTypography,
  headingTypography,
} from "../recordReabilitation/RecordRehabilitationStyle";
import SearchIcon from "@mui/icons-material/Search";
import mobileImg from "../../assets/images/images/uploads/download-device.png";
import shadowImg from "../../assets/images/images/download-device-shadow.png";
const rollInLeft = keyframes`
  0% {
    -webkit-transform: translateY(-40px) rotate(0);
            transform: translateX(100) rotate(0);
    opacity: 2;
  }
  100% {
    -webkit-transform: translateY(100px) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 2;
  }
`;
const Hold = styled(Box)(({ roll }) => ({
  visibility: !roll && "visible",
  animation: roll && `${rollInLeft} 1s ease-out both`,
}));
const JoinMail = () => {
  const [myElementIsVisible, setMyElementIsVisible] = React.useState();
  const [roll, setRoll] = React.useState(false);
  const homeRef = React.useRef(null);
  React.useEffect(() => {
    setRoll(true);
  }, []);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(homeRef.current);
  }, [homeRef]);
  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          height: "100%",
        }}
      >
        <Box sx={{ backgroundColor: "#fff", opacity: "0.8" }}>
          <Toolbar />
          <Toolbar />
          <Container>
            <Typography variant="h4" sx={headingTypography}>
              Join the mailing list..
            </Typography>
            <Box sx={headingParaBox}>
              <Typography variant="p" sx={headingParaTypography}>
                ..to be notified when the application is available in your store
                of preference
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Typography variant="p" sx={headingParaTypography}>
                {" "}
                Please bit a little patient, the hamster wheel behind this form
                takes some pushing.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "2rem",
                pb: "4rem",
              }}
            >
              <FormControl sx={{ m: 1, width: "50ch" }}>
                <OutlinedInput
                  sx={{ borderRadius: "28px", padding: "1px 10px" }}
                  type="text"
                  placeholder="Email"
                  endAdornment={
                    <InputAdornment position="end" sx={{ padding: "1px 10px" }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Hold roll={myElementIsVisible ? roll : ""}>
              <Box
                sx={{ pb: "2rem", display: "flex", justifyContent: "center" }}
                ref={homeRef}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    // width: { xs: "90%", sx: "100%", md: "100%" },
                  }}
                >
                  <Box
                    component="img"
                    src={mobileImg}
                    sx={{ maxWidth: { xs: "100%", md: "77%" } }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    component="img"
                    src={shadowImg}
                    sx={{
                      maxWidth: { xs: "100%", md: "78%" },
                      position: "relative",
                    }}
                  />
                </Box>
              </Box>
            </Hold>
          </Container>
          <Box sx={{ textAlign: "center", pb: "4rem" }}>
            <Container>
              <Grid container>
                <Grid item sm={3}></Grid>
                <Grid item sm={6}>
                  <Typography variant="p" sx={headingParaTypography}>
                    {" "}
                    We don't like spam; no-one does. Your email address will
                    only be used to inform you when the application is released.
                  </Typography>
                </Grid>
                <Grid item sm={3}></Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default JoinMail;
