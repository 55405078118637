import bgImg from "../../assets/images/images/hero-image-bg.jpg";
export const backgroundImg = {
  "&.MuiBox-root": {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
  },
};
export const backgroundColor = {
  "&.MuiBox-root": {
    backgroundColor: "#379bbc",
    opacity: "0.8",
    paddingBottom: "55px",
    width: "100%",
    height: "100%",
    pt: { xs: "12rem", sm: "12rem", md: "8rem" },
  },
};

export const gridFlex = {
  "&.MuiGrid-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const gridTypography = {
  "&.MuiTypography-root": {
    lineHeight: "1.4",
    fontSize: { xs: "31px", sm: "43px" },
    marginBottom: "15px",
  },
};
export const gridTypographyP = {
  "&.MuiTypography-root": {
    fontSize: "17px",
    lineHeight: "1.5",
    color: "white",
  },
};
export const gridImg = {
  "&.MuiBox-root": {
    maxWidth: "100%",
    height: "auto",
    display: "block",
  },
};
