import { Container, Toolbar, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Layout from "../../layout/appbar/Layout";
import Footer from "./Footer";

const EvidenceSource = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Layout />
      <Toolbar />
      <Container>
        <Toolbar />
        <Toolbar />
        <Typography variant="h5" sx={{ fontWeight: "600" }}>
          Published evidence for quadriceps exercise in the management of
          non-traumatic anterior knee pain
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rio E, Kidgell D, Purdam C, Gaida J, Moseley GL, Pearce AJ, Cook J.
          Isometric exercise induces analgesia and reduces inhibition in
          patellar tendinopathy. Br J Sports Med. 2015 Oct;49(19):1277-83. doi:
          10.1136/bjsports-2014-094386. Epub 2015 May 15. PMID: 25979840.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rio E, van Ark M, Docking S, Moseley GL, Kidgell D, Gaida JE, van den
          Akker-Scheek I, Zwerver J, Cook J. Isometric Contractions Are More
          Analgesic Than Isotonic Contractions for Patellar Tendon Pain:
          <br /> An In-Season Randomized Clinical Trial. Clin J Sport Med. 2017
          May;27(3):253-259. doi: 10.1097/JSM.0000000000000364. PMID: 27513733.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Breda SJ, Oei EHG, Zwerver J, Visser E, Waarsing E, Krestin GP, de Vos
          RJ. Effectiveness of progressive tendon-loading exercise therapy in
          patients with patellar tendinopathy: a randomised clinical trial. Br J
          Sports Med. 2021 May;55(9):501-509. doi: 10.1136/bjsports-2020-
          103403. Epub 2020 Nov 20. PMID: 33219115; PMCID: PMC8070614
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Núñez-Martínez P, Hernández-Guillen D. Management of Patellar
          Tendinopathy Through Monitoring, Load Control, and Therapeutic
          Exercise: A Systematic Review. J Sport Rehabil. 2021 Dec 23:1-14. doi:
          10.1123/jsr.2021-0117. Epub ahead of print. PMID: 34942594.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Crossley KM, van Middelkoop M, Barton CJ, Culvenor AG. Rethinking
          patellofemoral pain: Prevention, management and long-term
          consequences. Best Pract Res Clin Rheumatol. 2019 Feb;33(1):48-65.
          doi: 10.1016/j.berh.2019.02.004. Epub 2019 Feb 22. PMID: 31431275.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Challoumas D, Pedret C, Biddle M, Ng NYB, Kirwan P, Cooper B, Nicholas
          P,Wilson S, Clifford C, Millar NL. Management of patellar
          tendinopathy: a systematic review and network meta-analysis of
          randomised studies. BMJ Open Sport Exerc Med. 2021 Nov
          29;7(4):e001110. doi: 10.1136/bmjsem- 2021-001110. PMID: 34900334;
          PMCID: PMC8634001.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Mersmann F, Laube G, Marzilger R, Bohm S, Schroll A, Arampatzis A. A
          Functional High-Load Exercise Intervention for the Patellar Tendon
          Reduces Tendon Pain Prevalence During a Competitive Season in
          Adolescent Handball Players. Front Physiol. 2021 Mar 10;12:626225.
          doi: 10.3389/fphys.2021.626225. PMID: 33776790; PMCID: PMC7987778.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Agergaard AS, Svensson RB, Malmgaard-Clausen NM, Couppé C, Hjortshoej
          MH,Doessing S, Kjaer M, Magnusson SP. Clinical Outcomes, Structure,
          and Function Improve With Both Heavy and Moderate Loads in the
          Treatment of Patellar Tendinopathy: A Randomized Clinical Trial. Am J
          Sports Med. 2021 Mar;49(4):982-993. doi: 10.1177/0363546520988741.
          Epub 2021 Feb 22. PMID: 33616456.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Vang C, Niznik A. The Effectiveness of Isometric Contractions Compared
          With Isotonic Contractions in Reducing Pain For In-Season Athletes
          With Patellar Tendinopathy. J Sport Rehabil. 2020 Oct
          12;30(3):512-515. doi:10.1123/jsr.2019-0376. PMID: 33049706.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Clifford C, Challoumas D, Paul L, Syme G, Millar NL. Effectiveness of
          isometric exercise in the management of tendinopathy: a systematic
          review and meta-analysis of randomised trials. BMJ Open Sport Exerc
          Med. 2020 Aug 4;6(1):e000760. doi: 10.1136/bmjsem-2020-000760. PMID:
          32818059; PMCID: PMC7406028.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Holden S, Lyng K, Graven-Nielsen T, Riel H, Olesen JL, Larsen LH,
          Rathleff MS. Isometric exercise and pain in patellar tendinopathy: A
          randomized crossover trial. J Sci Med Sport. 2020 Mar;23(3):208-214.
          doi:10.1016/j.jsams.2019.09.015. Epub 2019 Oct 10. PMID: 31735531.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Vander Doelen T, Jelley W. Non-surgical treatment of patellar
          tendinopathy:A systematic review of randomized controlled trials. J
          Sci Med Sport. 2020Feb;23(2):118-124. doi:
          10.1016/j.jsams.2019.09.008. Epub 2019 Sep 13. PMID: 31606317.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          van Rijn D, van den Akker-Scheek I, Steunebrink M, Diercks RL, Zwerver
          J, van der Worp H. Comparison of the Effect of 5 Different Treatment
          Options for Managing Patellar Tendinopathy: A Secondary Analysis. Clin
          J Sport Med. 2019 May;29(3):181-187. doi:
          10.1097/JSM.0000000000000520. PMID: 31033610.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Basas Á, Cook J, Gómez MA, Rafael MA, Ramirez C, Medeiros B, Lorenzo
          A. Effects of a strength protocol combined with electrical stimulation
          on patellar tendinopathy: 42 months retrospective follow-up on 6
          high-level jumping athletes. Phys Ther Sport. 2018 Nov;34:105- 112.
          doi: 10.1016/j.ptsp.2018.09.005. Epub 2018 Sep 18. PMID: 30265995.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Pearson SJ, Stadler S, Menz H, Morrissey D, Scott I, Munteanu S,
          Malliaras P. Immediate and Short-Term Effects of Short- and Long-
          Duration Isometric Contractions in Patellar Tendinopathy. Clin J Sport
          Med. 2020 Jul;30(4):335-340. doi: 10.1097/JSM.0000000000000625. PMID:
          30095504.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Lim HY, Wong SH. Effects of isometric, eccentric, or heavy slow
          resistance exercises on pain and function in individuals with patellar
          tendinopathy: A systematic review. Physiother Res Int. 2018
          Oct;23(4):e1721. doi: 10.1002/pri.1721. Epub 2018 Jul 4. PMID:
          29972281.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Kim S, Park J. Impact of Severity and Duration of Anterior Knee Pain
          on Quadriceps Function and Self-reported Function. J Athl Train. 2022
          Feb 23. doi: 10.4085/1062-6050-0647.21. Epub ahead of print. PMID:
          35192713.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rabello LM, van den Akker-Scheek I, Brink MS, Maas M, Diercks RL,
          Zwerver J. Association Between Clinical and Imaging Outcomes After
          Therapeutic Loading Exercise in Patients Diagnosed With Achilles or
          Patellar Tendinopathy at Short-and Long-Term Follow-up: A Systematic
          Review. Clin J Sport Med. 2020 Jul;30(4):390-403. doi:
          10.1097/JSM.0000000000000624. PMID: 29952842.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          van Ark M, Rio E, Cook J, van den Akker-Scheek I, Gaida JE, Zwerver J,
          Docking S. Clinical Improvements Are Not Explained by Changes in
          Tendon Structure on Ultrasound Tissue Characterization After an
          Exercise Program for Patellar Tendinopathy. Am J Phys Med Rehabil.
          2018 Oct;97(10):708-714. doi: 10.1097/PHM.0000000000000951. PMID:
          29649012.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rio E, van Ark M, Docking S, Moseley GL, Kidgell D, Gaida JE, van den
          Akker-Scheek I, Zwerver J, Cook J. Isometric Contractions Are More
          Analgesic Than Isotonic Contractions for Patellar Tendon Pain: An
          In-Season Randomized Clinical Trial. Clin J Sport Med. 2017
          May;27(3):253-259. doi: 10.1097/JSM.0000000000000364. PMID: 27513733.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          van Ark M, Cook JL, Docking SI, Zwerver J, Gaida JE, van den Akker-
          Scheek I,Rio E. Do isometric and isotonic exercise programs reduce
          pain in athletes with patellar tendinopathy in-season? A randomised
          clinical trial. J Sci Med Sport. 2016 Sep;19(9):702-6. doi:
          10.1016/j.jsams.2015.11.006. Epub 2015 Dec 7. PMID: 26707957.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Earl-Boehm JE, Bolgla LA, Emory C, Hamstra-Wright KL, Tarima S, Ferber
          R. Treatment Success of Hip and Core or Knee Strengthening for
          Patellofemoral Pain: Development of Clinical Prediction Rules. J Athl
          Train. 2018 Jun;53(6):545-552. doi: 10.4085/1062-6050-510-16. Epub
          2018 Jun 12. PMID: 29893604; PMCID:PMC6089029.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Malliaras P, Barton CJ, Reeves ND, Langberg H. Achilles and patellar
          tendinopathy loading programmes : a systematic review comparing
          clinical outcomes and identifying potential mechanisms for
          effectiveness. Sports Med. 2013 Apr;43(4):267-86. doi:
          10.1007/s40279-013-0019-z. PMID: 23494258.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Barton CJ, Lack S, Hemmings S, Tufail S, Morrissey D. The 'Best
          Practice Guide to Conservative Management of Patellofemoral Pain':
          incorporating level 1 evidence with expert clinical reasoning. Br J
          Sports Med. 2015 Jul;49(14):923-34. doi: 10.1136/bjsports-2014-
          093637. Epub 2015 Feb 25. PMID: 25716151.
        </Typography>
        <Toolbar />
        <Toolbar />
        <Typography variant="h5">
          Mendonça LM, Bittencourt NFN, Alves LEM, Resende RA, Serrão FV.
          Interventions used for Rehabilitation and Prevention of Patellar
          Tendinopathy in athletes: a survey of Brazilian Sports Physical
          Therapists. Braz J Phys Ther. 2020 Jan-Feb;24(1):46-53. doi:
          10.1016/j.bjpt.2018.12.001. Epub 2018 Dec 19.PMID: 30581095; PMCID:
          PMC6994308.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Scattone Silva R, Nakagawa TH, Ferreira AL, Garcia LC, Santos JE,
          Serrão FV. Lower limb strength and flexibility in athletes with and
          without patellar tendinopathy. Phys Ther Sport. 2016 Jul;20:19-25.
          doi: 10.1016/j.ptsp.2015.12.001. Epub 2015 Dec 19. PMID: 27325535.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Acaröz Candan S, Sözen H, Arı E. Electromyographic activity of
          quadriceps muscles during eccentric squat exercises: implications for
          exercise selection in patellar tendinopathy. Res Sports Med. 2021 Dec
          2:1-11. doi:10.1080/15438627.2021.2010200. Epub ahead of print. PMID:
          34856831.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rio E, Purdam C, Girdwood M, Cook J. Isometric Exercise to Reduce Pain
          in Patellar Tendinopathy In-Season: Is It Effective "on the Road"?
          Clin J Sport Med. 2019 May;29(3):188-192. doi:
          10.1097/JSM.0000000000000549. PMID: 31033611.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Patellar tendinopathy: clinical diagnosis, load management, and advice
          for challenging case presentations P Malliaras, J Cook, C Purdam, E
          Rio - journal of orthopaedic & sports ..., 2015 - jospt.org <br />
          <br />
          Kaya D, Citaker S, Kerimoglu U, Atay OA, Nyland J, Callaghan M, Yakut
          Y,Yüksel I, Doral MN. Women with patellofemoral pain syndrome have
          quadriceps femoris volume and strength deficiency. Knee Surg Sports
          Traumatol Arthrosc. 2011 Feb;19(2):242-7. doi:
          10.1007/s00167-010-1290-2. Epub 2010 Oct 15. PMID: 20953760.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Heintjes E, Berger MY, Bierma-Zeinstra SM, Bernsen RM, Verhaar JA,
          Koes BW.Exercise therapy for patellofemoral pain syndrome. Cochrane
          Database Syst Rev.2003;(4):CD003472. doi: 10.1002/14651858.CD003472.
          Update in: Cochrane Database Syst Rev. 2015;1:CD003472. PMID:
          14583980.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Pearson SJ, Stadler S, Menz H, Morrissey D, Scott I, Munteanu S,
          Malliaras P. Immediate and Short-Term Effects of Short- and Long-
          Duration Isometric Contractions in Patellar Tendinopathy. Clin J Sport
          Med. 2020 Jul;30(4):335-340. doi: 10.1097/JSM.0000000000000625. PMID:
          30095504.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Pearson SJ, Stadler S, Menz H, Morrissey D, Scott I, Munteanu S,
          Malliaras P. Immediate and Short-Term Effects of Short- and Long-
          Duration Isometric Contractions in Patellar Tendinopathy. Clin J Sport
          Med. 2020 Jul;30(4):335-340. doi: 10.1097/JSM.0000000000000625. PMID:
          30095504.
        </Typography>
        <Toolbar />
        <Typography variant="h5" sx={{ fontWeight: "600" }}>
          Evidence for mapping during load testing and activity modification
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Hannington M, Tait T, Docking S, Cook J, Owoeye O, Bonello C, Emery C,
          Pasanen K, Edwards S, Rio E. Prevalence and pain distribution of
          anterior knee pain in college basketball players. J Athl Train. 2021
          Jul 30. doi:10.4085/1062-6050-0604.20. Epub ahead of print. PMID:
          34329433.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Sprague AL, Couppé C, Pohlig RT, Snyder-Mackler L, Silbernagel KG.
          Pain-guided activity modification during treatment for patellar
          tendinopathy: a feasibility and pilot randomized clinical trial. Pilot
          Feasibility Stud. 2021 Feb 25;7(1):58. doi: 10.1186/s40814-021-
          00792-5. PMID: 33632313; PMCID: PMC7905015.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Coombes BK, Mendis MD, Hides JA. Evaluation of patellar tendinopathy
          using the single leg decline squat test: Is pain location important?
          Phys Ther Sport. 2020 Nov;46:254-259. doi: 10.1016/j.ptsp.2020.10.002.
          Epub 2020 Oct 6. PMID: 33059234.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Hannington M, Docking S, Cook J, Edwards S, Rio E. Self-reported
          jumpers' knee is common in elite basketball athletes - But is it all
          patellar tendinopathy? Phys Ther Sport. 2020 May;43:58-64. doi:
          10.1016/j.ptsp.2020.01.012. Epub 2020 Jan 23. PMID: 32088601. Rio E,
          Girdwood M, Thomas J, Garofalo C, Fortington LV, Docking S. Pain
          mapping of the anterior knee: injured athletes know best. Scand J
          Pain. 2018 Jul 26;18(3):409-416. doi: 10.1515/sjpain-2018-0046. PMID:
          29794274.
        </Typography>
        <Toolbar />
        <Typography variant="h5" sx={{ fontWeight: "600" }}>
          Evidence for external pacing during exercise
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rio E, Kidgell D, Moseley GL, Cook J. Elevated corticospinal
          excitability in patellar tendinopathy compared with other anterior
          knee pain or no pain. Scand J Med Sci Sports. 2016 Sep;26(9):1072-9.
          doi: 10.1111/sms.12538. Epub 2015 Sep 15. PMID: 26369282.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rio E, Kidgell D, Purdam C, Gaida J, Moseley GL, Pearce AJ, Cook J.
          Isometric exercise induces analgesia and reduces inhibition in
          patellar tendinopathy. Br J Sports Med. 2015 Oct;49(19):1277-83. doi:
          10.1136/bjsports-2014-094386. Epub 2015 May 15. PMID: 25979840.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Alibazi RJ, Frazer AK, Pearce AJ, Tallent J, Avela J, Kidgell DJ.
          Corticospinal and intracortical excitability is modulated in the knee
          extensors after acute strength training. J Sports Sci. 2021 Nov 19:1-
          10. doi: 10.1080/02640414.2021.2004681. Epub ahead of print. PMID:
          34796778.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Tallent J, Woodhead A, Frazer AK, Hill J, Kidgell DJ, Howatson G.
          Corticospinal and spinal adaptations to motor skill and resistance
          training: Potential mechanisms and implications for motor
          rehabilitation and athletic development. Eur J Appl Physiol. 2021
          Mar;121(3):707- 719. doi: 10.1007/s00421-020-04584-2. Epub 2021 Jan 2.
          PMID: 33389142.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Manca A, Hortobágyi T, Carroll TJ, Enoka RM, Farthing JP, Gandevia SC,
          Kidgell DJ, Taylor JL, Deriu F. Contralateral Effects of Unilateral
          Strength and Skill Training: Modified Delphi Consensus to Establish
          Key Aspects of Cross-Education. Sports Med. 2021 Jan;51(1):11-20. doi:
          10.1007/s40279-020-01377-7. PMID: 33175329; PMCID: PMC7806569.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Siddique U, Rahman S, Frazer A, Leung M, Pearce AJ, Kidgell DJ.
          Task-dependent modulation of corticospinal excitability and inhibition
          following strength training. J Electromyogr Kinesiol. 2020
          Jun;52:102411. doi: 10.1016/j.jelekin.2020.102411. Epub 2020 Mar 9.
          PMID: 32244044.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Mason J, Frazer AK, Avela J, Pearce AJ, Howatson G, Kidgell DJ.
          Tracking the corticospinal responses to strength training. Eur J Appl
          Physiol. 2020 Apr;120(4):783-798. doi: 10.1007/s00421-020-04316-6.
          Epub 2020 Feb 14. PMID: 32060740.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Mason J, Frazer AK, Jaberzadeh S, Ahtiainen JP, Avela J, Rantalainen
          T,Leung M, Kidgell DJ. Determining the Corticospinal Responses to
          Single Bouts of Skill and Strength Training. J Strength Cond Res. 2019
          Sep;33(9):2299-2307. doi: 10.1519/JSC.0000000000003266. PMID:
          31343552.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Mason J, Frazer AK, Pearce AJ, Goodwill AM, Howatson G, Jaberzadeh S,
          Kidgell DJ. Determining the early corticospinal- motoneuronal
          responses to strength training: a systematic review and meta-analysis.
          Rev Neurosci. 2019 Jul 26;30(5):463-476. doi:
          10.1515/revneuro-2018-0054. PMID: 30864400.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Frazer AK, Pearce AJ, Howatson G, Thomas K, Goodall S, Kidgell DJ.
          Determining the potential sites of neural adaptation to
          cross-education: implications for the cross-education of muscle
          strength. Eur J Appl Physiol. 2018 Sep;118(9):1751-1772. doi:
          10.1007/s00421-018-3937- 5. Epub 2018 Jul 11. PMID: 29995227.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Brownstein CG, Ansdell P, Škarabot J, Frazer A, Kidgell D, Howatson G,
          Goodall S, Thomas K. Motor cortical and corticospinal function differ
          during an isometric squat compared with isometric knee extension. Exp
          Physiol. 2018 Sep;103(9):1251-1263. doi: 10.1113/EP086982. Epub 2018
          Aug 5. PMID: 29928769.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Leung M, Rantalainen T, Teo WP, Kidgell D. The ipsilateral
          corticospinal responses to cross-education are dependent upon the
          motor-training intervention. Exp Brain Res. 2018 May;236(5):1331-
          1346. doi: 10.1007/s00221-018-5224-4. Epub 2018 Mar 6. PMID: 29511785.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Leung M, Rantalainen T, Teo WP, Kidgell D. The corticospinal responses
          of metronome-paced, but not self-paced strength training are similar
          to motor skill training. Eur J Appl Physiol. 2017
          Dec;117(12):2479-2492. doi: 10.1007/s00421-017-3736-4. Epub 2017 Oct
          10. PMID: 29018949.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Rio E, Kidgell D, Moseley GL, Gaida J, Docking S, Purdam C, Cook J.
          Tendon neuroplastic training: changing the way we think about tendon
          rehabilitation: a narrative review. Br J Sports Med. 2016
          Feb;50(4):209-15. doi: 10.1136/bjsports-2015-095215. Epub 2015 Sep 25.
          PMID: 26407586; PMCID: PMC4752665.
        </Typography>
        <Toolbar />
        <Typography variant="h5">
          Leung M, Rantalainen T, Teo WP, Kidgell D. Motor cortex excitability
          is not differentially modulated following skill and strength training.
          Neuroscience.2015 Oct 1;305:99-108. doi:
          10.1016/j.neuroscience.2015.08.007. Epub 2015 Aug 7. PMID: 26259901.
        </Typography>
      </Container>
      <Toolbar />
      <Footer />
    </div>
  );
};

export default EvidenceSource;
