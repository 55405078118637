import {
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import React from "react";
import screenSnap from "../../assets/images/images/screenSnap1.png";
import screenSnap2 from "../../assets/images/images/screenSnap2.png";
import screenSnap3 from "../../assets/images/images/screenSnap3.png";
import screenSnap4 from "../../assets/images/images/screenSnap4.png";
import screenSnap5 from "../../assets/images/images/screenSnap5.png";
import screenSnap6 from "../../assets/images/images/screenSnap6.png";
import {
  gridBox,
  gridStyle,
  headingParaBox,
  headingParaTypography,
  headingTypography,
} from "./RecordRehabilitationStyle";
const rollInLeft = keyframes`
  0% {
    -webkit-transform: translateY(200px) rotate(0);
            transform: translateX(100) rotate(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(100px) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 2;
  }
`;
const Hold = styled(Box)(({ roll }) => ({
  visibility: !roll && "hidden",
  animation: roll && `${rollInLeft} 1s ease-out both`,
}));

const RecordRehabilitation = () => {
  const [myElementIsVisible, setMyElementIsVisible] = React.useState();
  const [roll, setRoll] = React.useState(false);
  const recoredRef = React.useRef(null);
  React.useEffect(() => {
    setRoll(true);
  }, []);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setMyElementIsVisible(entry.isIntersecting);
      if (entry.isIntersecting) observer.unobserve(entry.target);
    });
    observer.observe(recoredRef.current);
  }, [recoredRef]);
  return (
    <div>
      <Toolbar />
      <Box ref={recoredRef}>
        <Hold roll={myElementIsVisible ? roll : ""}>
          <Box>
            <Container>
              <Typography variant="h4" sx={headingTypography}>
                Record your rehabilitation sessions.
              </Typography>
              {/* <Grid container> */}
              {/* <Grid item md={3}></Grid> */}
              {/* <Grid item md={6}> */}
              <Box sx={headingParaBox}>
                <Typography variant="p" sx={headingParaTypography}>
                  Weight training only gets your muscles stronger, but people
                  with pain have changes to the way their brain controls their
                  muscles. So to fire up the connections between different parts
                  of your brain and your muscles, we personalise your training.
                </Typography>
              </Box>
            </Container>
            {/* </Grid>
          <Grid item md={3}></Grid>
        </Grid> */}
          </Box>
          <Container sx={{ paddingLeft: { xs: "0px", sm: "24px" } }}>
            <Grid container sx={gridStyle} spacing={3}>
              <Grid item xs={4} md={2}>
                <Box component="img" src={screenSnap} sx={gridBox} />
              </Grid>
              <Grid item xs={4} md={2}>
                <Box component="img" src={screenSnap2} sx={gridBox} />
              </Grid>
              <Grid item xs={4} md={2}>
                <Box component="img" src={screenSnap3} sx={gridBox} />
              </Grid>
              <Grid item xs={4} md={2}>
                <Box component="img" src={screenSnap4} sx={gridBox} />
              </Grid>
              <Grid item xs={4} md={2}>
                <Box component="img" src={screenSnap5} sx={gridBox} />
              </Grid>
              <Grid item xs={4} md={2}>
                <Box component="img" src={screenSnap6} sx={gridBox} />
              </Grid>
            </Grid>
          </Container>
          <Toolbar />
        </Hold>
      </Box>
    </div>
  );
};

export default RecordRehabilitation;
