import React from "react";
import Home from "./components/home/Home";
import { Route, Routes } from "react-router-dom";
import EvidenceSource from "./components/Footer/EvidenceSource";
import Privacy from "./components/Footer/Privacy";
import Terms from "./components/Footer/Terms";
import Remove from "./components/remove/Remove";

function App() {
  let queryString = new URLSearchParams(window.location.search);
  let hideNav = queryString.get("/remove");
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/download-evidence" element={<EvidenceSource />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/term" element={<Terms />} />
      </Routes>
    </div>
  );
}

export default App;
